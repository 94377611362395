
module.exports = () => {
    document.addEventListener('DOMContentLoaded', function() {

        // get buttons
        let seeMoreButtons = document.querySelectorAll('.table .see-more');
        seeMoreButtons.forEach(button => {
            button.addEventListener('click', function() {
                button.parentNode.classList.remove('collapse');
            })
        });

        let seeLessButtons = document.querySelectorAll('.table .see-less');
        seeLessButtons.forEach(button => {
            button.addEventListener('click', function() {
                button.parentNode.classList.add('collapse');
            })
        })

    })
    
}