
module.exports = () => {
    document.addEventListener('DOMContentLoaded', function() {

        let contactFormDrawer = document.querySelector('#contact-form-drawer');
        let mainContactForm = document.querySelector('#contact-form-drawer .wpcf7');
        let submitButton = document.querySelector('#contact-form-drawer input[type="submit"]');

        submitButton.addEventListener('click', function() {
            contactFormDrawer.classList.add('loading');
        });

        mainContactForm.addEventListener('wpcf7submit', function() {
            contactFormDrawer.classList.remove('loading');
            contactFormDrawer.classList.add('loaded');
        });

    })   
}



