// 
let animations = require('./animations');
animations();

let menus = require('./menus');
menus();

let searchBar = require('./search_bar.js');
searchBar();

let slider = require('./slider');
slider();

let cursor = require('./cursor');
cursor();

let wpcf7 = require('./wpcf7')
wpcf7();


// layouts
let base = require('./layouts/__base');
base();
let faqs = require('./layouts/faqs');
faqs();
let table = require('./layouts/table');
table();
let video_embed = require('./layouts/video_embed');
video_embed();
let our_work = require('./layouts/our_work');
our_work();