
module.exports = () => {

    // document.addEventListener('DOMContentLoaded', function() {

    //     // Select all elements with the class name 'custom_crsr'
    //     const elements = document.querySelectorAll('.cursor');

    //     // Function to log mouse position relative to the element
    //     function updateCursorPosition(event, cursorEl) {
    //         const rect = event.currentTarget.getBoundingClientRect();
    //         const x = event.clientX - rect.left; // X position relative to the element
    //         const y = event.clientY - rect.top;  // Y position relative to the element

    //         // Update the position of the cursor element
    //         cursorEl.style.left = `${x}px`;
    //         cursorEl.style.top = `${y}px`;
    //         console.log(`Mouse Position - X: ${x}, Y: ${y}`);
    //     }

    //     // Add event listeners to each element
    //     elements.forEach(element => {

    //         let cursorEl = document.createElement('span');
    //         cursorEl.classList.add('cursor-el');
    //         element.appendChild(cursorEl);

    //         const mouseMoveHandler = (e) => updateCursorPosition(e, cursorEl);

    //         element.addEventListener('mouseenter', (e) => {
    //             // Add mousemove event listener when mouse enters
    //             element.addEventListener('mousemove', mouseMoveHandler);
    //         });

    //         element.addEventListener('mouseleave', (e) => {
    //             // Remove mousemove event listener when mouse leaves
    //             element.removeEventListener('mousemove', mouseMoveHandler);
    //         });

    //         document.addEventListener('scroll', (e) => {
    //             // Add mousemove event listener when mouse enters
    //             element.addEventListener('mousemove', mouseMoveHandler);
    //         });
    //     });
    // })


    // document.addEventListener('DOMContentLoaded', function() {
    //     const elements = document.querySelectorAll('.cursor');
    //     let lastMouseX = 0;
    //     let lastMouseY = 0;
    
    //     function updateCursorPosition(cursorEl) {
    //         cursorEl.style.left = `${lastMouseX}px`;
    //         cursorEl.style.top = `${lastMouseY}px`;
    //     }
    
    //     const mouseMoveHandler = (e, cursorEl) => {
    //         const rect = e.currentTarget.getBoundingClientRect ? e.currentTarget.getBoundingClientRect() : cursorEl.parentNode.getBoundingClientRect();
            
    //         if (e.clientX) {
    //             lastMouseX = e.clientX - rect.left ; // Adjust for scroll
    //         } else {
    //             lastMouseX = lastMouseX - rect.left;
    //         }
    //         if (e.clientY) {
    //             lastMouseY = e.clientY - rect.top ;  // Adjust for scroll
    //         } else {
    //             lastMouseY = lastMouseY - rect.top ; 
    //         }
    //         updateCursorPosition(cursorEl);
    //     };
    
    //     elements.forEach(element => {
    //         let cursorEl = document.createElement('span');
    //         cursorEl.classList.add('cursor-el');
    //         element.appendChild(cursorEl);
    
    //         element.addEventListener('mouseenter', () => {
    //             element.addEventListener('mousemove', (e) => mouseMoveHandler(e, cursorEl));
    //             updateCursorPosition(cursorEl); // Set initial position
    //         });
    
    //         element.addEventListener('mouseleave', () => {
    //             element.removeEventListener('mousemove', (e) => mouseMoveHandler(e, cursorEl));
    //         });
    //     });
    
    //     // Scroll event listener to update cursor position
    //     document.addEventListener('scroll', (e) => {
    //         const visibleCursorElements = document.querySelectorAll('.cursor .cursor-el');

    //         visibleCursorElements.forEach(cursorElement => {
    //             mouseMoveHandler(e, cursorElement);
    //         });
    //     });
    // });



    document.addEventListener('DOMContentLoaded', function(e) {
        const elements = document.querySelectorAll('.cursor');
        let lastMouseX = 0;
        let lastMouseY = 0;

        document.addEventListener('mousemove', (e) => {
            lastMouseX = e.clientX; // Store global mouse position
            lastMouseY = e.clientY; // Store global mouse position
        });



    
        function updateCursorPosition(cursorEl, element) {
            const rect = element.getBoundingClientRect();
            cursorEl.style.left = `${lastMouseX - rect.left}px`;
            cursorEl.style.top = `${lastMouseY - rect.top}px`;
        }
    
        const mouseMoveHandler = (e, cursorEl, element) => {
            lastMouseX = e.clientX; // Store global mouse position
            lastMouseY = e.clientY; // Store global mouse position
            updateCursorPosition(cursorEl, element);
        };
    
        elements.forEach(element => {
            let cursorEl = document.createElement('span');
            cursorEl.classList.add('cursor-el');
            element.appendChild(cursorEl);
    
            element.addEventListener('mouseenter', () => {
                element.addEventListener('mousemove', (e) => mouseMoveHandler(e, cursorEl, element));
                updateCursorPosition(cursorEl, element); // Set initial position
            });
    
            element.addEventListener('mouseleave', () => {
                element.removeEventListener('mousemove', (e) => mouseMoveHandler(e, cursorEl, element));
            });
        });
    
        // Update cursor position during scrolling
        document.addEventListener('scroll', () => {
            elements.forEach(element => {
                const cursorEl = element.querySelector('.cursor-el');
                if (cursorEl) {
                    updateCursorPosition(cursorEl, element); // Update based on last known mouse position
                }
            });
        });
    });
    
    
}



