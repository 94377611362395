
/* --- SLIDER --- */
module.exports = () => {
	jQuery(document).ready(function () {

		// jQuery('.slider').each(function() {
		// 	jQuery(this).slick({
		// 		dots: true,
		// 		infinite: false,
		// 		speed: 500,
		// 		slidesToShow: 3,
		// 		slidesToScroll: 3,
		// 		arrows: false,
		// 		centerPadding: '40px',
		// 		autoplay: true,
		// 		autoplaySpeed: 2000,
		// 		mobileFirst: true,
		// 		swipeToSlide: true,
		// 		responsive: [
		// 			{
		// 				breakpoint: 900,
		// 				settings: {
		// 					slidesToShow: 2,
		// 					slidesToScroll: 2
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 540,
		// 				settings: {
		// 					slidesToShow: 1,
		// 					slidesToScroll: 1
		// 				}
		// 			}
		// 			// You can unslick at a given breakpoint now by adding:
		// 			// settings: "unslick"
		// 			// instead of a settings object
		// 		]
		// 	})
		// });
		// jQuery(window).resize(function(){
		// 	jQuery('.slider').slick('resize'); // recalc on window resize
		// });


		// continuous pixel by pixel infinite slider
		// jQuery('.slider-infinite').forEach(function() {
		// 	jQuery(this).slick({
		// 		speed: 2000, // increase for slower, decrease for faster
		// 		autoplay: true,
		// 		autoplaySpeed: 0,
		// 		cssEase: 'linear',
		// 		slidesToShow: 1,
		// 		slidesToScroll: 1,
		// 		variableWidth: true,
		// 		arrows: false
		// 	}); 
		// })
		// jQuery(window).resize(function(){
		// 	jQuery('.slider-infinite').slick('resize'); // recalc on window resize
		// });

		jQuery('.logos-slick').each(function() {
			jQuery(this).slick({
				speed: 3000, // increase for slower, decrease for faster
				autoplay: true,
				autoplaySpeed: 0,
				cssEase: 'linear',
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
				arrows: false
			}); 
		})
		jQuery(window).resize(function(){
			jQuery('.logos-slick').slick('resize'); // recalc on window resize
		});


		jQuery('.how_we_do_it .pills-slider').each(function() {
			jQuery(this).slick({
				speed: 3000, // increase for slower, decrease for faster
				autoplay: true,
				autoplaySpeed: 0,
				cssEase: 'linear',
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
				arrows: false
			}); 
		})
		jQuery(window).resize(function(){
			jQuery('.how_we_do_it .pills-slider').slick('resize'); // recalc on window resize
		});

		jQuery('.how_we_do_it .pills-slider-reverse').each(function() {
			jQuery(this).slick({
				speed: 3000, // increase for slower, decrease for faster
				autoplay: true,
				autoplaySpeed: 0,
				cssEase: 'linear',
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
				arrows: false,
				rtl: true,
			}); 
		})
		jQuery(window).resize(function(){
			jQuery('.how_we_do_it .pills-slider-reverse').slick('resize'); // recalc on window resize
		});



		// jQuery('.case-studies-homepage-flex').each(function() {
		// 	jQuery(this).slick({
		// 		dots: true,
		// 		infinite: false,
		// 		speed: 500,
		// 		slidesToShow: 1.2,
		// 		slidesToScroll: 1,
		// 		arrows: false,
		// 		centerPadding: '40px',
		// 		autoplay: false,
		// 		mobileFirst: true,
		// 		swipeToSlide: true,
		// 		responsive: [
		// 			{
		// 				breakpoint: 600,
		// 				settings: "unslick"
		// 			}
		// 			// You can unslick at a given breakpoint now by adding:
		// 			// settings: "unslick"
		// 			// instead of a settings object
		// 		]
		// 	})
		// });
		// jQuery(window).resize(function(){
		// 	jQuery('.case-studies-homepage-flex').slick('resize'); // recalc on window resize
		// });

		jQuery('.reviews-slider').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				arrows: false,
				centerPadding: '40px',
				autoplay: false,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 560,
						settings: {
							slidesToShow: 2.2
						}
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			})
		});
		jQuery(window).resize(function(){
			jQuery('.reviews-slider').slick('resize'); // recalc on window resize
		});

		jQuery('.timeline-slider').each(function() {
			jQuery(this).slick({
				dots: false,
				infinite: false,
				speed: 500,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				arrows: false,
				centerPadding: '40px',
				autoplay: false,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 560,
						settings: {
							slidesToShow: 1.2
						}
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			});
		});
		jQuery(window).resize(function(){
			jQuery('.timeline-slider').slick('resize'); // recalc on window resize
		});
		jQuery('.timeline-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
			let bar = this.nextElementSibling.querySelector('span');
			let totalSlides = this.querySelectorAll('.slick-slide').length;
			let amountToMove = (100 / totalSlides) * nextSlide;
			bar.style.marginLeft = amountToMove + "%";
		})



		jQuery('.case-studies-flex').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				arrows: false,
				centerPadding: '40px',
				autoplay: false,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					// {
					// 	breakpoint: 1000,
					// 	settings: {
					// 		slidesToShow: 3.2,
					// 		slidesToScroll: 1
					// 	}
					// },
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			})
		});
		jQuery(window).resize(function(){
			jQuery('.case-studies-flex').slick('resize'); // recalc on window resize
		});


		jQuery('.featured_posts .blog-posts-flex').each(function() {
			jQuery(this).slick({
				dots: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				arrows: false,
				centerPadding: '40px',
				autoplay: false,
				mobileFirst: true,
				swipeToSlide: true,
				responsive: [
					// {
					// 	breakpoint: 1000,
					// 	settings: {
					// 		slidesToShow: 3.2,
					// 		slidesToScroll: 1
					// 	}
					// },
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 900,
						settings: "unslick"
					}
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				]
			})
		});
		jQuery(window).resize(function(){
			jQuery('.featured_posts .blog-posts-flex').slick('resize'); // recalc on window resize
		});


	});
}