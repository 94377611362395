module.exports = () => {
    jQuery(document).ready(function() {

        // open and close contact drawer
        let openContactButtons = document.querySelectorAll('a[href="#contact"], #contact-form-drawer .close');
        let contactDrawer = document.getElementById('contact-form-drawer');
        let contactDrawerBg = document.querySelector('#contact-form-drawer + .bg');
        let scrollSmoother = ScrollSmoother.get();

        openContactButtons.forEach((button) => {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                if (contactDrawer.classList.contains('open')) {
                    contactDrawer.classList.remove('open');
                    scrollSmoother.paused(false);
                } else {
                    contactDrawer.classList.add('open');
                    scrollSmoother.paused(true);
                }
            });
        });
        contactDrawerBg.addEventListener('click', function() {
            contactDrawer.classList.remove('open');
            scrollSmoother.paused(false);
        });



        // MOBILE MENU EXPANDING/COLLAPSING 
        let hamburger = document.getElementById('hamburger');
        let mobileMenu = document.getElementById('mobile-menu');
        let mobileDrawerBg = document.querySelector('.mobile-menu + .bg');
        
        hamburger.onclick = function() {
            // if we are closing the menu
            if (this.classList.contains('is-active')) {
                this.classList.remove('is-active');
                mobileMenu.classList.remove('mobile-menu-open');
                contactDrawer.classList.remove('open');
                scrollSmoother.paused(false);
            } 
            // otherwise we are opening the menu
            else {
                this.classList.add('is-active');
                mobileMenu.classList.add('mobile-menu-open');
                scrollSmoother.paused(true);
            }
        }

        mobileDrawerBg.addEventListener('click', function() {
            hamburger.classList.remove('is-active');
            mobileMenu.classList.remove('mobile-menu-open');
            contactDrawer.classList.remove('open');
            ScrollSmoother.paused(false);
        });

        let servicesMobileItem = document.querySelector('.mobile-menu .mega');
        servicesMobileItem.addEventListener('click', function(e) {
            e.preventDefault();
        });
    
        // Expand sub menus on the mobile menu
        let buttons = document.querySelectorAll('.mobile-menu .menu-item-has-children b, .mobile-menu .mega b');
        buttons.forEach((button) => {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                let button = this;
                let subMenu = this.parentNode.nextElementSibling;
    
                if (subMenu.classList.contains('open')) {
                    subMenu.classList.remove('open');
                    subMenu.style.height = '0px';
                    this.classList.toggle('open');
                } else {
                    subMenu.classList.add('open');
                    subMenu.style.height = subMenu.scrollHeight + 'px';
                    this.classList.toggle('open');
                }
            })
        });

        // esure



    })
}