
module.exports = () => {
    jQuery(document).ready(function() {

        let playButtons = document.querySelectorAll('.video-container .play-button');
        playButtons.forEach((button) => {
            button.addEventListener('click', function() {
                let image = this.parentNode.querySelector('img');
                let iframe = this.parentNode.querySelector('iframe');

                image.classList.remove('show');
                button.classList.remove('show');
                iframe.classList.add('show');

                // image.style.opacity = 0;
                // image.style.position = 'absolute';
                // image.style.pointerEvents = 'none';

                // iframe.style.opacity = 1;
                // iframe.style.position = 'static';


                // button.style.display = 'none';
            })
        })

    })
}