module.exports = () => {
    document.addEventListener('DOMContentLoaded', function() {
        let grid = jQuery('.our-work-flex').isotope({
            // options
            itemSelector: '.case-study',
            layoutMode: 'fitRows'
        });

        

        let filter_buttons = jQuery('.filter-buttons button');
        filter_buttons.on('click', async function() {
            const filter_value = jQuery(this).attr('data-filter-value');
            
            let elems = document.querySelectorAll(`.our-work-flex > ${filter_value}`);
            let iter = 0;

            elems.forEach((elem) => {
                let marginTop;
                iter % 2 === 0 ? marginTop = '0px' : marginTop = '65px';
                elem.style.marginTop = marginTop;
                iter++;
            });

            let currentSelected = document.querySelector('.our_work button.selected');
            currentSelected.classList.remove('selected')
            this.classList.add('selected');

            grid.isotope({ filter: filter_value });

        });

    })
}